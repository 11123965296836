import { Pipe, PipeTransform } from '@angular/core';
import { Task, TASK_LEGAL_STATE } from '@dougs/task/dto';

@Pipe({
  name: 'taskHeaderClass',
  standalone: true,
})
export class TaskHeaderClassPipe implements PipeTransform {
  transform(task: Task, isLegalCollaborator = false): string {
    if (isLegalCollaborator) {
      switch (task.legalState) {
        case TASK_LEGAL_STATE.TO_PRODUCE:
        case TASK_LEGAL_STATE.FORMALITIES:
          return 'task-card__header--alert';
        case TASK_LEGAL_STATE.SIGNING:
          return '';
        case TASK_LEGAL_STATE.CAPITAL_DEPOSIT:
          return 'task-card__header--pink';
        case TASK_LEGAL_STATE.REGISTERING:
        case TASK_LEGAL_STATE.PENDING_DEPOSIT:
          return 'task-card__header--purple';
        case TASK_LEGAL_STATE.PENDING_QUOTE:
          return 'task-card__header--yellow';
        case TASK_LEGAL_STATE.WAITING_FOR_INFORMATION:
          return 'task-card__header--orange';
        default:
          return 'task-card__header--neutral';
      }
    }
    return task.overdue ? 'task-card__header--alert' : '';
  }
}
