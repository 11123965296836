import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dougs-payroll-variable-card',
  templateUrl: './payroll-variable-card.component.html',
  styleUrls: ['./payroll-variable-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class PayrollVariableCardComponent {
  @Input() label!: string;
  @Input() isEditable = true;
  @Input() color: 'gray' | 'yellow' | 'blue' | 'green' = 'gray';

  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
}
