import { CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { getMonth } from 'date-fns';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { OwnershipService } from '@dougs/core/ownership';
import {
  AvatarComponent,
  AvatarMessageComponent,
  ButtonComponent,
  DougsDatePipe,
  FlashMessagesService,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalService,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task, TaskForm, TaskFormInlineGroupValueItem } from '@dougs/task/dto';
import { TemplateTaskStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { PayrollVariablesComponent } from './payroll-variables/payroll-variables.component';

@Component({
  selector: 'dougs-payroll-survey',
  templateUrl: './payroll-survey.component.html',
  styleUrls: ['./payroll-survey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    LoaderComponent,
    AvatarMessageComponent,
    NgFor,
    AvatarComponent,
    PayrollVariablesComponent,
    ModalFooterDirective,
    ButtonComponent,
    CurrencyPipe,
    DougsDatePipe,
  ],
})
export class PayrollSurveyComponent implements OnInit {
  payslipMonth!: number;
  isLoading = true;
  form!: TaskForm | null;

  constructor(
    @Inject(MODAL_DATA)
    public data: Task,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly metricsService: MetricsService,
    private readonly templateTaskStateService: TemplateTaskStateService,
    public readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly modalService: ModalService,
    private readonly ownershipService: OwnershipService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.ownershipService.isMyCompany() && !this.userStateService.loggedInUser.isAccountantOrAdmin) {
      this.metricsService.pushGAEvent('/me/payroll-survey');
    }

    if (this.data.metadata.payslipDate) {
      this.payslipMonth = getMonth(new Date(this.data.metadata.payslipDate));
    }

    this.form = await this.templateTaskStateService.getTaskForm(this.data);

    this.metricsService.pushMixpanelEvent('Social Payroll Survey Modal Viewed', {
      'Company is TNS': this.companyStateService.activeCompany?.metadata?.isTNS,
      'Company has employees': this.form?.inlineGroup?.value?.items?.some((item) => !item.isEmployeeLikeCandidate),
      'Active employees Count': this.form?.inlineGroup?.value?.items?.length,
      'Payslip Date': this.data.metadata.payslipDate,
      'User is Accountant or Admin': this.userStateService.loggedInUser.isAccountantOrAdmin,
      'User is Payroll Manager':
        this.userStateService.loggedInUser.isAccountantOrAdmin &&
        this.companyStateService.activeCompany?.flags?.includes(
          `payrollmanager:${this.userStateService.loggedInUser.id}`,
        ),
    });

    this.isLoading = false;

    this.cdr.markForCheck();
  }

  async onSubmit(payrollSurveyForm: NgForm): Promise<void> {
    if (payrollSurveyForm.valid && !this.data.completedAt) {
      if (this.ownershipService.isMyCompany()) {
        this.metricsService.pushGAEvent('completed-payroll-survey');
      }

      if (this.form?.inlineGroup?.value?.items) {
        const formUpdated: TaskForm | null = await this.templateTaskStateService.updateTaskForm(
          this.data,
          this.form.inlineGroup.value.items,
        );

        if (formUpdated) {
          this.flashMessagesService.show('Vos variables ont bien été prises en compte.', {
            type: 'success',
            timeout: 5000,
          });
          this.modalRef.close(true);
        }
      }
    } else {
      this.flashMessagesService.show('Veuillez remplir tous les champs obligatoires.', {
        type: 'error',
        timeout: 5000,
      });
    }
  }

  async openPartnerModal(partner: TaskFormInlineGroupValueItem): Promise<void> {
    const { PartnerModalComponent } = await import('@dougs/partners/ui');
    this.modalService.open(PartnerModalComponent, {
      data: {
        partnerId: partner.partnerId,
        companyId: this.data.companyId,
      },
    });
  }
}
