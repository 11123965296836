import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '@dougs/task/dto';

@Pipe({
  name: 'targetIdentifier',
  standalone: true,
})
export class TargetIdentifierPipe implements PipeTransform {
  transform(task: Task): string {
    if (task.target?.profile) {
      return task.target.profile.fullName || task.target.profile.email;
    }

    return '';
  }
}
