import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dougs-round-check',
  templateUrl: 'round-check.component.html',
  styleUrls: ['round-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class RoundCheckComponent {
  @Input() checked = false;
}
