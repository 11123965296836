<ng-container *ngIf="taskMetadataComponentService.startDateValueChanges$ | async"></ng-container>
<div class="task-metadata px-8 py-4">
  <div class="task-metadata__row">
    <a
      [routerLink]="task.companyId | replaceActiveCompanyIdInUrl | async"
      [title]="task | targetIdentifier"
      class="overflow-ellipsis mr-8"
    >
      <i class="fal fa-folder mr-4" dougsTooltip="Dossier"></i>
      <span class="color-admin">{{ task | targetIdentifier }}</span>
    </a>
    <div [dougsDropdownTrigger]="dropdownUsers" class="overflow-ellipsis task-metadata__assigned-at">
      <i class="fal fa-user-secret mr-4" dougsTooltip="Assignée à"></i>
      <span>{{ task.assignee?.profile?.fullName || 'Assignée à personne' }}</span>
    </div>
  </div>
  <div class="task-metadata__row mt-4">
    <div class="overflow-ellipsis mr-8">
      <i [dougsTooltip]="task | creationDateTooltipText" class="fal fa-calendar-plus mr-4"></i>
      <span>{{ task.createdAt | formatDistanceToNow: true }}</span>
    </div>
    <div
      [formControl]="taskMetadataComponentService.startDateFormControl"
      [dougsDatepicker]="startDatepicker"
      [useDefaultTaskPill]="true"
      [ngClass]="{ 'color-error': task.overdue }"
      class="overflow-ellipsis task-metadata__row__start-date"
      ngDefaultControl
    >
      <i class="fal fa-clock mr-4" dougsTooltip="À faire le"></i>
      <span>{{ (task.startDate | formatDistanceToNowRoundedToDay: true) || 'Définir une date' }}</span>
    </div>
  </div>
  <div
    (click)="taskActionFacadeService.executeTaskAction(task)"
    *ngIf="taskActionFacadeService.getTaskActionAvailable(task)"
    class="task-metadata__action mt-4"
  >
    <i *ngIf="taskActionFacadeService.getTaskActionIcon(task) as icon" [ngClass]="icon" class="mr-4"></i>
    <span class="color-primary">{{ taskActionFacadeService.getTaskActionLabel(task) }}</span>
  </div>
</div>
<dougs-dropdown
  (opened)="isAssigneeOpened = true"
  (closed)="isAssigneeOpened = false"
  #dropdownUsers
  [widthToRefElement]="false"
>
  <dougs-task-user-dropdown
    *ngIf="isAssigneeOpened"
    (selectUser)="taskMetadataComponentService.assignTask($event?.id)"
    [shouldShowMe]="shouldShowMe"
    [taskIds]="[task.id]"
    [shouldShowUnassign]="true"
  ></dougs-task-user-dropdown>
</dougs-dropdown>
<dougs-datepicker #startDatepicker></dougs-datepicker>
