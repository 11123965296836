import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BlankStateComponent, ButtonComponent, ModalService, SelectModalComponent } from '@dougs/ds';
import { Task, TaskFormInlineGroupValueItem, VariableDescriptors } from '@dougs/task/dto';
import { PayrollVariableAbsenceComponent } from './payroll-variable-absence/payroll-variable-absence.component';
import { PayrollVariableBonusComponent } from './payroll-variable-bonus/payroll-variable-bonus.component';
import { PayrollVariableCardComponent } from './payroll-variable-card/payroll-variable-card.component';
import { PayrollVariableKilometricIndemnityComponent } from './payroll-variable-kilometric-indemnity/payroll-variable-kilometric-indemnity.component';
import { PayrollVariableOtherComponent } from './payroll-variable-other/payroll-variable-other.component';
import { PayrollVariableOvertimeComponent } from './payroll-variable-overtime/payroll-variable-overtime.component';
import { PayrollVariablePackedLunchComponent } from './payroll-variable-packed-lunch/payroll-variable-packed-lunch.component';
import { PayrollVariableTransportSubscriptionComponent } from './payroll-variable-transport-subscription/payroll-variable-transport-subscription.component';

@Component({
  selector: 'dougs-payroll-variables',
  templateUrl: './payroll-variables.component.html',
  styleUrls: ['./payroll-variables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    PayrollVariableCardComponent,
    PayrollVariableBonusComponent,
    PayrollVariableTransportSubscriptionComponent,
    PayrollVariablePackedLunchComponent,
    PayrollVariableKilometricIndemnityComponent,
    PayrollVariableOvertimeComponent,
    PayrollVariableOtherComponent,
    NgSwitchDefault,
    PayrollVariableAbsenceComponent,
    ButtonComponent,
    BlankStateComponent,
  ],
})
export class PayrollVariablesComponent {
  @Input() partner!: TaskFormInlineGroupValueItem;
  @Input() task!: Task;
  @Input() variableDescriptors!: VariableDescriptors;
  @Input() month!: Date;

  @Output() updateVariable: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly modalService: ModalService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async addVariable(): Promise<void> {
    const value: string | null | undefined = (
      await lastValueFrom(
        this.modalService.open<string>(SelectModalComponent, {
          data: {
            title: 'Sélectionner une variable',
            items: this.variableDescriptors ? Object.values(this.variableDescriptors) : [],
          },
        }).afterClosed$,
      )
    ).data;

    if (value) {
      this.partner.variables = [
        ...(this.partner.variables || []),
        {
          value,
          data: {},
          createdAt: Date.now().toString(),
        },
      ];

      setTimeout(() => {
        this.updateVariable.emit();
      }, 0);

      this.cdr.markForCheck();
    }
  }

  onDelete(index: number) {
    this.partner.variables = [...this.partner.variables.slice(0, index), ...this.partner.variables.slice(index + 1)];

    setTimeout(() => {
      this.updateVariable.emit();
    }, 0);

    this.cdr.markForCheck();
  }
}
