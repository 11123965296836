<div
  class="week-input"
  [ngClass]="{ 'mb-16': !last }"
  *ngFor="let week of weeks; trackBy: trackByWeek; let last = last"
>
  <label [for]="'overtimeCountForWeek' + week.weekNumber">
    Semaine {{ week.weekNumber }}
    <em>du {{ week.firstDay }} au {{ week.lastDay }}</em>
  </label>
  <dougs-form-field [noMargin]="true">
    <input
      [name]="'overtimeCountForWeek' + week.weekNumber + uuid"
      #overtimeCountForWeek="ngModel"
      dougsFormFieldControl
      min="0"
      step="0.25"
      [disabled]="!isEditable"
      [(ngModel)]="variableData['overtimeCountForWeek' + week.weekNumber]"
      [required]="true"
      type="number"
      [id]="'overtimeCountForWeek' + week.weekNumber"
    />
    <span dougsFormFieldError *ngIf="formService.isNgModelInvalid(overtimeCountForWeek)">
      <ng-container *ngIf="overtimeCountForWeek.errors.required">Ce champ est requis</ng-container>
      <ng-container *ngIf="overtimeCountForWeek.errors.min">Le montant doit être un nombre positif</ng-container>
    </span>
  </dougs-form-field>
  <p class="small">heures</p>
</div>
