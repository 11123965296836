<div dougsModalContent>
  <dougs-stepper
    *ngIf="countAccountsChecked < synchronizedAccounts.length"
    [steps]="3"
    [currentStep]="1"
    stepDetail="<p class='tiny'><strong>Sélectionnez</strong></p> <p class='tiny'>le compte à vérifier</p>"
  ></dougs-stepper>

  <h5 class="my-16" *ngIf="synchronizedAccounts.length - countAccountsChecked">
    Compte{{ synchronizedAccounts.length - countAccountsChecked > 1 ? 's' : '' }} à vérifier
    <span class="account-count" *ngIf="synchronizedAccounts.length - countAccountsChecked > 1">
      ({{ synchronizedAccounts.length - countAccountsChecked }})
    </span>
  </h5>

  <h5 class="my-16" *ngIf="!(synchronizedAccounts.length - countAccountsChecked)">
    {{ countAccountsChecked > 1 ? 'Comptes vérifiés' : 'Compte vérifié' }} avec succès
  </h5>

  <dougs-panel-info *ngIf="!countAccountsChecked">
    Cette action permet de vérifier la correspondance entre le solde présent sur vos
    <strong>relevés bancaires mensuels</strong>
    et le solde indiqué sur votre compte Dougs. Ça ne prend que quelques minutes ⏱
  </dougs-panel-info>

  <dougs-panel-info *ngIf="countAccountsChecked && countAccountsChecked < synchronizedAccounts.length">
    Il vous reste {{ synchronizedAccounts.length - countAccountsChecked }} compte{{
      synchronizedAccounts.length - countAccountsChecked > 1 ? 's' : ''
    }}
    : vous y êtes presque !
  </dougs-panel-info>

  <dougs-panel-info
    *ngIf="countAccountsChecked && countAccountsChecked === synchronizedAccounts.length && !bankReconciliationNeeded"
  >
    {{ synchronizedAccounts.length > 1 ? 'Vos comptes ont été vérifiés' : 'Votre compte a été vérifié' }} avec succès :
    tout est en ordre ! Merci de nous avoir aidé à réaliser cette action 👍
  </dougs-panel-info>

  <dougs-panel-info
    *ngIf="countAccountsChecked && countAccountsChecked === synchronizedAccounts.length && bankReconciliationNeeded"
  >
    Avec les documents fournis, nos équipes vont pouvoir régulariser la situation. Merci de nous avoir aidé à réaliser
    cette action 👍
  </dougs-panel-info>

  <ul *ngFor="let synchronizedAccount of synchronizedAccounts; trackBy: trackById" class="accounts-container pl-0">
    <li class="accounts-container__account-item">
      <div class="account-container py-8 pr-16" [ngClass]="'m-' + synchronizedAccount.color">
        <div class="account-title account-detail-border pl-8">
          <h6 class="account-title__bank-name">
            {{ synchronizedAccount.bankName }} ·
            <ng-container *ngIf="synchronizedAccount.memo">
              <span class="account-name color-gray">{{ synchronizedAccount.memo }}</span>
              <span> · </span>
            </ng-container>
            <span class="account-name color-gray">{{ synchronizedAccount.accountName }}</span>
          </h6>
        </div>
      </div>
      <dougs-button
        class="check-button"
        [fullWidth]="true"
        (click)="selectAccount(synchronizedAccount)"
        *ngIf="!reconciliationCompletedAccountsIds[synchronizedAccount.id]"
      >
        {{
          resumeReconciliationAccountsIds[synchronizedAccount.id]
            ? 'Reprendre la vérification'
            : 'Démarrer la vérification'
        }}
      </dougs-button>
      <dougs-button
        class="check-button"
        [fullWidth]="true"
        disabled="true"
        *ngIf="reconciliationCompletedAccountsIds[synchronizedAccount.id]"
      >
        Compte vérifié avec succès
      </dougs-button>
    </li>
  </ul>
</div>

<div dougsModalFooter *ngIf="countAccountsChecked && countAccountsChecked === synchronizedAccounts.length">
  <dougs-button [dougsModalClose]="task">Terminer</dougs-button>
</div>
