import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import {
  AvatarComponent,
  ButtonComponent,
  ControlFormFieldDirective,
  CopyToClipboardDirective,
  DropdownComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { ReferrerStateService } from '@dougs/subscription/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { ReferrerComponentService } from '../../services/referrer.component.service';
import { ReferrerPartnershipComponent } from './referrer-partnership/referrer-partnership.component';
import { ReferrerUserDropdownComponent } from './referrer-user-dropdown/referrer-user-dropdown.component';

@Component({
  selector: 'dougs-referrer',
  templateUrl: './referrer.component.html',
  styleUrls: ['./referrer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    ButtonComponent,
    CopyToClipboardDirective,
    NgIf,
    FormFieldComponent,
    SuffixFormFieldDirective,
    AvatarComponent,
    DropdownComponent,
    ReferrerUserDropdownComponent,
    ReferrerPartnershipComponent,
    AsyncPipe,
  ],
})
export class ReferrerComponent {
  @ViewChild('dropdownUsers') dropdownUsers!: DropdownComponent;

  public dropdownOpened = false;

  constructor(
    public readonly userStateService: UserStateService,
    public readonly referrerComponentService: ReferrerComponentService,
    public readonly companyStateService: CompanyStateService,
    public readonly referrerStateService: ReferrerStateService,
  ) {}

  openUsersDropdown(): void {
    if (this.dropdownUsers) {
      this.dropdownUsers.show();
      this.dropdownOpened = true;
    }
  }

  onDropdownClose(): void {
    this.dropdownOpened = false;
  }

  async onUpdateSelectedReferrer(selectedUser: User | null): Promise<void> {
    if (this.dropdownUsers) {
      this.dropdownUsers.hide();
      this.dropdownOpened = false;
    }
    await this.referrerComponentService.updateSelectedReferrer(selectedUser);
  }
}
