<div dougsModalTitle>
  <h6>{{ data.task.title }}</h6>
  <i *ngIf="isClosable" dougsModalClose class="fal fa-times"></i>
</div>

<div dougsModalContent>
  <div class="update-payment-card">
    <dougs-avatar-message
      class="mb-16"
      fullName="Sandy Dutka"
      size="medium"
      avatarUrl="https://s3.eu-west-3.amazonaws.com/files.dougs.fr/142ef4ca-d296-46ce-8a37-9e38223d1cea/1"
    >
      <p class="small">Bonjour {{ data.task.assignee?.profile?.firstName }},</p>
      <br />
      <p class="small" *ngIf="!!companyStateService.activeCompany.subscription?.cardExpirationDate">
        Nous avons remarqué que la carte de paiement que vous utilisez sur Dougs arrivera à expiration à la fin du mois
        {{ companyStateService.activeCompany.subscription?.cardExpirationDate | monthPrefix }}
        {{ companyStateService.activeCompany.subscription?.cardExpirationDate | date: 'yyyy' }} .
      </p>
      <p class="small" *ngIf="!companyStateService.activeCompany.subscription?.cardExpirationDate">
        Nous avons remarqué que vous n'avez pas encore renseigné une carte bancaire.
      </p>
      <br />
      <p class="small" *ngIf="!!companyStateService.activeCompany.subscription?.cardExpirationDate">
        Pour éviter tout retard de paiement, je vous invite à mettre à jour votre carte dès que vous la recevrez.
      </p>
      <p class="small" *ngIf="!companyStateService.activeCompany.subscription?.cardExpirationDate">
        Afin de pouvoir réaliser notre mission d'expertise comptable, merci de renseigner vos informations de carte
        bancaire.
      </p>
    </dougs-avatar-message>
  </div>
</div>

<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose *ngIf="isClosable">Me le rappeler plus tard</dougs-button>
  <dougs-button (click)="submit()">Mettre à jour ma carte</dougs-button>
</div>
