import { AsyncPipe, CurrencyPipe, KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ControlFormFieldDirective,
  DatepickerComponent,
  DatepickerDirective,
  DividerComponent,
  DougsDatePipe,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  PillComponent,
  SelectComponent,
  SelectOptionComponent,
  TooltipDirective,
  TrackByPipe,
} from '@dougs/ds';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BillingInvoiceService } from '@dougs/subscription/ui';
import { Task, TASK_LEGAL_STATE, TaskBillingInvoiceItem, TaskForm, TaskTemplate } from '@dougs/task/dto';
import { TemplateTaskStateService } from '@dougs/task/shared';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { ActiveSubtaskTitlePipe } from '../../../pipes/control-panel/task/active-subtask-title.pipe';
import { BillingInvoiceIconColorPipe } from '../../../pipes/control-panel/task/billing-invoice-icon-color.pipe';
import { BillingInvoiceIconPipe } from '../../../pipes/control-panel/task/billing-invoice-icon.pipe';
import { BillingInvoiceTooltipPipe } from '../../../pipes/control-panel/task/billing-invoice-tooltip.pipe';
import { BillingInvoiceTotalItemsPipe } from '../../../pipes/control-panel/task/billing-invoice-total-items.pipe';
import { IsEditableServicePipe } from '../../../pipes/control-panel/task/is-editable-service.pipe';
import { MakeDocumentsAvailableTooltipPipe } from '../../../pipes/control-panel/task/make-documents-available-tooltip.pipe';
import { TaskHeaderClassPipe } from '../../../pipes/control-panel/task/task-header-class.pipe';
import { ToggleIconPipe } from '../../../pipes/control-panel/task/toggle-icon.pipe';
import { LegalStatePipe } from '../../../pipes/tasks/task/legal-state.pipe';
import { TaskFormComponentService } from '../../../services/control-panel/task/task-form.component.service';
import { TaskComponentService } from '../../../services/task.component.service';
import { LegalCollaboratorComponentService } from '../../../services/tasks/legal/legal-collaborator.component.service';
import { TaskConversationComponent } from './task-conversation/task-conversation.component';
import { TaskFilesComponent } from './task-files/task-files.component';
import { TaskFormFieldsComponent } from './task-form-fields/task-form-fields.component';
import { TaskItemComponent } from './task-item/task-item.component';
import { TaskMetadataComponent } from './task-metadata/task-metadata.component';

@Component({
  selector: 'dougs-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    TooltipDirective,
    NgIf,
    PillComponent,
    DropdownTriggerDirective,
    TaskMetadataComponent,
    FormsModule,
    DatepickerDirective,
    DividerComponent,
    NgFor,
    TaskFormFieldsComponent,
    TaskItemComponent,
    TaskFilesComponent,
    TaskConversationComponent,
    DropdownComponent,
    DropdownOptionComponent,
    DatepickerComponent,
    AsyncPipe,
    CurrencyPipe,
    TrackByPipe,
    ToggleIconPipe,
    BillingInvoiceIconPipe,
    BillingInvoiceIconColorPipe,
    BillingInvoiceTooltipPipe,
    BillingInvoiceTotalItemsPipe,
    ActiveSubtaskTitlePipe,
    IsEditableServicePipe,
    DougsDatePipe,
    SelectComponent,
    KeyValuePipe,
    LegalStatePipe,
    SelectOptionComponent,
    ControlFormFieldDirective,
    FormFieldComponent,
    LabelFormFieldDirective,
    TaskHeaderClassPipe,
    MakeDocumentsAvailableTooltipPipe,
  ],
  providers: [TaskFormComponentService, TaskComponentService, LegalCollaboratorComponentService],
})
export class TaskComponent {
  showAskClientInformation = false;
  makeDocumentsAvailableDisabled = true;
  taskTemplate!: TaskTemplate | null;
  form: TaskForm | null = null;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  private _task!: Task;

  constructor(
    private readonly templateTaskStateService: TemplateTaskStateService,
    public readonly userStateService: UserStateService,
    private readonly taskFormComponentService: TaskFormComponentService,
    public readonly billingInvoiceService: BillingInvoiceService,
    private readonly cdr: ChangeDetectorRef,
    public readonly taskComponentService: TaskComponentService,
    public readonly legalCollaboratorComponentService: LegalCollaboratorComponentService,
  ) {}

  get task(): Task {
    return this._task;
  }

  @Input()
  set task(task: Task) {
    this._task = task;
    this.showAskClientInformation = this.taskComponentService.shouldShowAskClientInformation(task);
    this.makeDocumentsAvailableDisabled = this.taskComponentService.shouldDisableMakeDocumentsAvailable(task);
    void this.getTaskTemplate();
  }

  async getTaskTemplate(): Promise<void> {
    this.taskTemplate = await this.templateTaskStateService.getTaskTemplate(this.task.code, this.task.version);
    this.cdr.markForCheck();
  }

  async toggleCompleted(): Promise<void> {
    if (await this.taskComponentService.toggleCompleted(this.task)) {
      this.closeModal.emit();
    }
  }

  async abortTask(): Promise<void> {
    if (await this.taskComponentService.abortTask(this.task)) {
      this.closeModal.emit();
    }
  }

  async deleteTask(): Promise<void> {
    if (await this.taskComponentService.deleteTask(this.task)) {
      this.closeModal.emit();
    }
  }

  async toggleConfigurationExpanded(): Promise<void> {
    this.form = await this.taskComponentService.toggleConfigurationExpanded(this.task);
    this.cdr.markForCheck();
  }

  async changeTaskForm(): Promise<void> {
    const formChanged: TaskForm | null = await this.taskFormComponentService.changeTaskForm(this.task, this.form);

    if (formChanged) {
      this.form = formChanged;
      this.cdr.markForCheck();
    }
  }

  async updateTaskForm(): Promise<void> {
    const formUpdated: TaskForm | null = await this.taskFormComponentService.updateTaskForm(this.task, this.form);

    if (formUpdated) {
      this.form = formUpdated;
      this.cdr.markForCheck();
    }
  }

  async cancelTaskForm(): Promise<void> {
    this.form = await this.taskFormComponentService.cancelTaskForm(this.task);
    this.cdr.markForCheck();
  }

  trackById(_: number, item: TaskBillingInvoiceItem): number {
    return item.id;
  }

  keepOriginalOrder(): number {
    return 0;
  }

  protected readonly USER_FLAG = USER_FLAG;
  protected readonly TASK_LEGAL_STATE = TASK_LEGAL_STATE;
}
