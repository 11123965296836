import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import { generateUuidV4 } from '@dougs/core/utils';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
} from '@dougs/ds';
import { VariableData } from '@dougs/task/dto';

@Component({
  selector: 'dougs-payroll-variable-bonus',
  templateUrl: './payroll-variable-bonus.component.html',
  styleUrls: ['./payroll-variable-bonus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [
    FormFieldComponent,
    LabelFormFieldDirective,
    FormsModule,
    ControlFormFieldDirective,
    NgIf,
    ErrorFormFieldDirective,
  ],
})
export class PayrollVariableBonusComponent {
  @Input() isEditable = true;
  @Input() variableData!: VariableData;

  uuid = generateUuidV4();

  constructor(public formService: FormService) {}
}
