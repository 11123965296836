<div dougsModalContent *ngIf="isLoading" class="modal-content-loader-container">
  <dougs-loader></dougs-loader>
</div>

<form *ngIf="!isLoading" [formGroup]="bankStatementForm" (submit)="submitBankStatements()">
  <div dougsModalContent>
    <dougs-stepper
      [steps]="3"
      [currentStep]="3"
      stepDetail="<p class='tiny'>Envoyez vos</p> <p class='tiny'><strong>relevés bancaires mensuels</strong></p>"
    ></dougs-stepper>

    <h5 class="my-16">Relevé bancaire à joindre</h5>

    <div class="account-container py-8 pr-16" [ngClass]="'m-' + selectedAccount.color">
      <div class="account-title account-detail-border pl-8">
        <h6 class="account-title__bank-name">
          {{ selectedAccount.bankName }} ·
          <span class="account-name color-gray">{{ selectedAccount.accountName }}</span>
        </h6>
      </div>
    </div>

    <dougs-panel-info class="my-16">
      Nous avons besoin des
      <strong>relevés bancaires mensuels en PDF</strong>
      correspondant aux mois indiqués ci-dessous. Ils nous permettront d'identifier d'où provient la différence du solde
      affiché entre votre banque et votre compte Dougs.
    </dougs-panel-info>

    <div
      class="form-container"
      *ngFor="let balance of bankStatementForm.controls; let index = index; trackBy: trackByIndex"
      [formGroupName]="index"
    >
      <input type="hidden" formControlName="balanceId" />

      <dougs-bank-statement-card
        [validated]="bankStatements?.[getCurrentBalance(index).id]"
        [title]="getCurrentCardTitle(bankStatementForm, index)"
      >
        <dougs-input-datepicker label="Date du solde" [noMargin]="true" formControlName="date"></dougs-input-datepicker>

        <dougs-form-field class="file-input" [noMargin]="true">
          <div class="file file-list mt-8">
            <dougs-file-input
              [square]="true"
              acceptFileType="application/pdf"
              fileInputText="Ajouter un relevé"
              (uploadFiles)="onUploadFile({ balanceIndex: index, bankStatement: $event })"
              [multiple]="false"
              *ngIf="!bankStatements?.[getCurrentBalance(index).id]"
            ></dougs-file-input>
            <dougs-file-pill
              [square]="true"
              *ngIf="bankStatements?.[getCurrentBalance(index).id]"
              (deleteFile)="onDeleteFile({ balanceIndex: index, bankStatement: $event })"
              [attachment]="bankStatements?.[getCurrentBalance(index).id]"
            ></dougs-file-pill>
          </div>
        </dougs-form-field>
      </dougs-bank-statement-card>
    </div>
  </div>

  <div dougsModalFooter>
    <dougs-button color="secondary" (click)="previousStep()">Retour</dougs-button>

    <dougs-button type="submit" [disabled]="isBankStatementFormSubmitDisabled">Confirmer</dougs-button>
  </div>
</form>
