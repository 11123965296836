import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { FormService } from '@dougs/core/form';
import { generateUuidV4 } from '@dougs/core/utils';
import { ControlFormFieldDirective, FormFieldComponent, LabelFormFieldDirective } from '@dougs/ds';
import { VariableData } from '@dougs/task/dto';

@Component({
  selector: 'dougs-payroll-variable-other',
  templateUrl: './payroll-variable-other.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [FormFieldComponent, LabelFormFieldDirective, FormsModule, AutosizeModule, ControlFormFieldDirective],
})
export class PayrollVariableOtherComponent {
  @Input() isEditable = true;
  @Input() variableData!: VariableData;

  uuid = generateUuidV4();

  constructor(public formService: FormService) {}
}
