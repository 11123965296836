<ol class="stepper pl-0 m-auto">
  <li
    *ngFor="let step of steps; let index = index; trackBy: trackByIndex"
    class="stepper__step mb-16"
    [ngClass]="{
      'stepper__step--previous': currentStep > index + 1,
      'stepper__step--current': currentStep === index + 1
    }"
  >
    <div *ngIf="currentStep === index + 1" class="mt-8" [innerHTML]="stepDetail"></div>
  </li>
</ol>
