<div dougsModalTitle>Corrigez votre fiscalité dans Amazon Seller Central</div>
<div dougsModalContent>
  <dougs-avatar-message
    class="mb-16"
    size="medium"
    fullName="Jean-Charles Morel"
    avatarUrl="https://static.intercomassets.com/avatars/3724593/square_128/jean-charles_full-1579506174.jpg"
  >
    <p class="small mb-16">
      Le tax report que vous avez importé dans votre ventilation de
      {{ data.task.metadata.operationDate | date: 'MMMM yyyy' }} ne respecte pas vos obligations fiscales.
    </p>

    @if (data.task.metadata.taxation?.needsOss || data.task.metadata.taxation?.crossBorderSales) {
      <p class="small mb-16">
        <img class="mr-8" src="images/emoji/backhand-index-pointing-right.png" height="15" />
        <b>Vous devez renseigner votre numéro OSS dans les paramètres de votre Amazon Seller Central.</b>
      </p>
    }

    @if (data.task.metadata.taxation?.nationalSales || data.task.metadata.taxation?.crossBorderSales) {
      <p class="small mb-16">
        <img class="mr-8" src="images/emoji/backhand-index-pointing-right.png" height="15" />
        <b
          >Votre société doit avoir un numéro de TVA intracommunautaire
          {{ data.task.metadata.countriesWithoutSellerTaxRegistration }}.</b
        ><br />
        Pour ce faire, nous vous conseillons de vous rapprocher d'un représentant fiscal spécialisé dans les services
        d'Amazon.
      </p>

      <p class="small mb-16">
        <img class="mr-8" src="images/emoji/backhand-index-pointing-right.png" height="15" />
        <b>Vous devez renseigner ce(s) numéro(s) de TVA dans les paramètres de votre Amazon Seller Central.</b>
      </p>
    }

    <p class="small mb-16">
      Du fait de l'absence de ce(s) numéro(s) de TVA dans votre Seller Central, Amazon ne calcule pas la TVA des ventes
      impactées.
    </p>

    <p class="small mb-16">
      Nous avons corrigé vos ventes dans le respect des obligations fiscales en matière de TVA. Cependant, ces
      corrections peuvent avoir un impact sur votre marge.
    </p>

    <p class="small mb-16">
      <b>Nous vous invitons à régulariser la situation au plus vite.</b>
    </p>

    <p class="small">
      <img src="images/emoji/light-bulb.png" height="15" />
      Pour rappel, avoir un numéro de TVA dans chacun des pays de l'UE où vous détenez du stock est une obligation
      fiscale applicable à l'ensemble des pays de l'UE. De plus, Amazon oblige l'ensemble des vendeurs à s'enregistrer à
      l'OSS à des fins de reversement de la TVA dans les pays de destination où vous vendez.
    </p>
  </dougs-avatar-message>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
  <dougs-button type="button" [dougsModalClose]="true">J'ai mis à jour Amazon Seller Central</dougs-button>
</div>
