import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { Task } from '@dougs/task/dto';
import { BankReconciliationService } from '../../bank-reconciliation.service';
import { StepperComponent } from '../../stepper/stepper.component';

@Component({
  selector: 'dougs-bank-reconciliation-first-step',
  templateUrl: './bank-reconciliation-first-step.component.html',
  styleUrls: ['./bank-reconciliation-first-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalContentDirective,
    NgIf,
    StepperComponent,
    PanelInfoComponent,
    NgFor,
    NgClass,
    ButtonComponent,
    ModalFooterDirective,
    ModalCloseDirective,
  ],
})
export class BankReconciliationFirstStepComponent {
  private _synchronizedAccounts!: SynchronizedAccount[];
  private _reconciliationCompletedAccountsIds!: { [accountId: number]: boolean };
  resumeReconciliationAccountsIds!: { [accountId: number]: boolean };
  countAccountsChecked!: number;

  @Input()
  set synchronizedAccounts(synchronizedAccounts: SynchronizedAccount[]) {
    this._synchronizedAccounts = synchronizedAccounts;

    if (this._task) {
      this.resumeReconciliationAccountsIds = this.bankReconciliationService.getResumeReconciliationAccountsIds(
        synchronizedAccounts,
        this._task,
      );
    }
  }
  get synchronizedAccounts() {
    return this._synchronizedAccounts;
  }
  @Input()
  set reconciliationCompletedAccountsIds(reconciliationCompletedAccountsIds: { [accountId: number]: boolean }) {
    this._reconciliationCompletedAccountsIds = reconciliationCompletedAccountsIds;
    const accountsReconciliationCompleted = Object.values(this.reconciliationCompletedAccountsIds).filter(
      (accountIdReconciliationCompleted) => accountIdReconciliationCompleted,
    );

    this.countAccountsChecked = accountsReconciliationCompleted.length || 0;
  }
  get reconciliationCompletedAccountsIds() {
    return this._reconciliationCompletedAccountsIds;
  }
  @Input() bankReconciliationNeeded!: boolean;

  private _task!: Task;

  get task(): Task {
    return this._task;
  }

  @Input()
  set task(task: Task) {
    this._task = task;

    if (this.synchronizedAccounts) {
      this.resumeReconciliationAccountsIds = this.bankReconciliationService.getResumeReconciliationAccountsIds(
        this.synchronizedAccounts,
        task,
      );
    }
  }

  @Output() selectAccountEvent = new EventEmitter<SynchronizedAccount>();

  constructor(private readonly bankReconciliationService: BankReconciliationService) {}

  selectAccount(selectedAccount: SynchronizedAccount) {
    this.selectAccountEvent.emit(selectedAccount);
  }

  trackById(index: number, synchronizedAccount: SynchronizedAccount): number {
    return synchronizedAccount.id;
  }
}
