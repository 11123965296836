<ng-container *ngIf="onboardingStageByLawsProjectService.refreshCapitalDeposit$ | async"></ng-container>
<ng-container *ngIf="onboardingStageByLawsProjectService.capitalDeposit$ | async"></ng-container>
<ng-container *ngIf="onboardingStageByLawsProjectService.initUserTasks$ | async"></ng-container>

<div class="card mt-16">
  <div class="card__title p-16 pl-24">
    <h5>Étapes du projet de statuts</h5>
  </div>
  <div class="card__content p-24">
    <div
      class="card__content__row mb-16"
      *ngFor="let task of onboardingStageByLawsProjectService.tasks; let index = index; trackBy: 'id' | trackBy"
    >
      <div class="card__content__row__left">
        <div>
          <dougs-round-check [checked]="false">{{ index + 1 }}</dougs-round-check>
        </div>
        <div>
          <p class="color-primary-700">{{ task.title }}</p>
        </div>
      </div>
      <div class="card__content__row__center">
        <ng-container *ngIf="task.userTask; else dougsTask">
          <p>Vous</p>
        </ng-container>
        <ng-template #dougsTask>
          <img alt="Logo Dougs" width="80" src="images/logo/dougs-logo.svg" />
        </ng-template>
      </div>
      <div class="card__content__row__right">
        <ng-container *ngIf="task.code === 'bank' && false">
          <!-- waiting for next scope with action details -->
          <dougs-button id="web-button">Transmettre l'attestation</dougs-button>
          <dougs-button id="mobile-button" size="large" [fullWidth]="true">Transmettre l'attestation</dougs-button>
        </ng-container>

        <dougs-button
          *ngIf="task.code === 'proofOfAddress'"
          [disabled]="!onboardingStageByLawsProjectService.canAccessProofOfAddress"
          [fullWidth]="true"
          [size]="(responsiveService.isMobile | async) ? 'large' : 'medium'"
          (click)="onboardingStageByLawsProjectService.openAttachProofAddressModal()"
        >
          {{ titleBtnProofOfAddress }}
          <i *ngIf="onboardingStageByLawsProjectService.hasProofOfAddress" class="fal fa-check ml-8"></i>
        </dougs-button>

        <dougs-button
          *ngIf="task.code === 'projectValidation'"
          [disabled]="!onboardingStageByLawsProjectService.canAccessProjectValidation"
          [fullWidth]="true"
          [size]="(responsiveService.isMobile | async) ? 'large' : 'medium'"
          (click)="onboardingStageByLawsProjectService.openProjectValidationModal(data?.personInCharge)"
        >
          {{ titleBtnProjectValidation }}
          <i *ngIf="onboardingStageByLawsProjectService.isProjectValidated" class="fal fa-check ml-8"></i>
        </dougs-button>

        <dougs-button
          *ngIf="task.code === 'depositCapital'"
          [disabled]="!onboardingStageByLawsProjectService.canAccessCapitalDeposit"
          [fullWidth]="true"
          [size]="(responsiveService.isMobile | async) ? 'large' : 'medium'"
          (click)="onboardingStageByLawsProjectService.openCapitalDepositModal(data?.personInCharge)"
        >
          {{ titleBtndepositCapital }}
          <i *ngIf="onboardingStageByLawsProjectService.hasCapitalDeposit" class="fal fa-check ml-8"></i>
        </dougs-button>
      </div>
    </div>
  </div>
  <div class="card__footer p-16 pl-24">
    <p>Dès la réception de votre Kbis, votre entreprise sera créée !</p>
  </div>
</div>
