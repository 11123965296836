import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import { generateUuidV4 } from '@dougs/core/utils';
import { ControlFormFieldDirective, ErrorFormFieldDirective, FormFieldComponent } from '@dougs/ds';
import { VariableData, VariableDescriptorsDataWeek } from '@dougs/task/dto';

@Component({
  selector: 'dougs-payroll-variable-overtime',
  templateUrl: './payroll-variable-overtime.component.html',
  styleUrls: ['./payroll-variable-overtime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [NgFor, NgClass, FormFieldComponent, FormsModule, ControlFormFieldDirective, NgIf, ErrorFormFieldDirective],
})
export class PayrollVariableOvertimeComponent {
  @Input() isEditable = true;
  @Input() variableData!: VariableData;
  @Input() weeks: VariableDescriptorsDataWeek[] = [];

  uuid = generateUuidV4();

  constructor(public formService: FormService) {}

  trackByWeek(index: number, week: VariableDescriptorsDataWeek): number {
    return week.weekNumber;
  }
}
