import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { differenceInDays, endOfMonth, startOfMonth } from 'date-fns';
import { Subscription } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import { FormService } from '@dougs/core/form';
import { generateUuidV4 } from '@dougs/core/utils';
import {
  DividerComponent,
  FileInputComponent,
  FilePillComponent,
  InputDatepickerComponent,
  RadioComponent,
  RadioGroupComponent,
} from '@dougs/ds';
import { Task, TaskFormInlineGroupValueItem, Variable, VariableData } from '@dougs/task/dto';
import { ControlPanelTasksStateService, UserTasksStateService } from '@dougs/task/shared';

@Component({
  selector: 'dougs-payroll-variable-absence',
  templateUrl: './payroll-variable-absence.component.html',
  styleUrls: ['./payroll-variable-absence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [
    NgIf,
    InputDatepickerComponent,
    FormsModule,
    CommonModule,
    RadioGroupComponent,
    RadioComponent,
    FileInputComponent,
    NgFor,
    FilePillComponent,
    DividerComponent,
  ],
})
export class PayrollVariableAbsenceComponent implements OnInit, OnDestroy {
  private _variable!: Variable;
  private _month!: Date;
  private _variableData!: VariableData;

  uuid = generateUuidV4();
  absenceStartDateMinDate: Date | null = null;
  absenceStartDateMaxDate: Date | null = null;
  absenceEndDateMinDate: Date | null = null;
  workStoppingAttachments: Attachment[] = [];
  workAccidentDeclarationAttachments: Attachment[] = [];
  shouldShowAbsenceEndDuration = false;

  userTasksSubscription!: Subscription;

  @Input() isEditable = true;
  @Input()
  set variableData(variableData: VariableData) {
    this._variableData = variableData;
    this.computeMinDate();
  }

  get variableData(): VariableData {
    return this._variableData;
  }

  @Input() partner!: TaskFormInlineGroupValueItem;
  @Input() task!: Task;
  @Input()
  set month(month: Date) {
    this._month = month;
    this.computeMaxDate();
    this.computeMinDate();
  }

  get month(): Date {
    return this._month;
  }

  @Input()
  set variable(variable: Variable) {
    this._variable = variable;
    this.computeMinDate();
  }

  get variable(): Variable {
    return this._variable;
  }

  constructor(
    public formService: FormService,
    private readonly cdr: ChangeDetectorRef,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly userTasksStateService: UserTasksStateService,
  ) {}

  ngOnInit(): void {
    this.userTasksSubscription = this.userTasksStateService.tasks$.subscribe((userTasks) => {
      const taskFound: Task | undefined = userTasks.find((userTask) => userTask.id === this.task.id);

      if (taskFound) {
        this.workStoppingAttachments = this.filterAttachments(taskFound.attachments, 'workStopping');
        this.workAccidentDeclarationAttachments = this.filterAttachments(
          taskFound.attachments,
          'workAccidentDeclaration',
        );

        this.cdr.markForCheck();
      }
    });
  }

  computeMaxDate(): void {
    this.absenceStartDateMaxDate = endOfMonth(new Date(this.month));
  }

  computeMinDate(): void {
    if (this.variable && this.month && this.variableData) {
      this.absenceStartDateMinDate = this.variable.value === 'paidVacation' ? startOfMonth(new Date(this.month)) : null;

      this.absenceEndDateMinDate = this.variableData?.absenceStartDate
        ? new Date(this.variableData.absenceStartDate)
        : startOfMonth(new Date(this.month));

      this.shouldShowAbsenceEndDuration =
        (this.variable.value === 'paidVacation' ||
          this.variable.value === 'leaveWithoutPay' ||
          this.variable.value === 'unjustifiedAbsence') &&
        !!this.variableData?.absenceStartDate &&
        !!this.variableData?.absenceEndDate &&
        differenceInDays(new Date(this.variableData.absenceStartDate), new Date(this.variableData.absenceEndDate)) < 0;

      this.cdr.markForCheck();
    }
  }

  filterAttachments(attachments: Attachment[], type: string): Attachment[] {
    return attachments.filter(
      (attachment) =>
        attachment.type === type &&
        attachment.metadata?.partnerId === this.partner.partnerId &&
        attachment.metadata?.variableCreatedAt === this.variable.createdAt,
    );
  }

  async addAttachment(file: File, fileType: string): Promise<void> {
    await this.controlPanelTasksStateService.uploadTaskAttachment(this.task, file, {
      fileType,
      metadata: {
        partnerId: this.partner.partnerId,
        variableCreatedAt: this.variable.createdAt,
      },
    });
  }

  async removeAttachment(attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(this.task, attachment);
  }

  ngOnDestroy(): void {
    this.userTasksSubscription?.unsubscribe();
  }
}
