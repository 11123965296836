import { Inject, Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { from, map } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PartnerComponentService } from '@dougs/partners/ui';
import { Task, TaskESignProcedure, TaskTemplate } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';

export type PartnerGroup = FormGroup<{
  partner: FormControl<Partner | null>;
  errors: FormControl<string[] | null>;
  selected: FormControl<boolean | null>;
}>;

export type ArrayPartnersGroup = FormArray<PartnerGroup>;

@Injectable()
export class YousignTaskModalService {
  formPartners = new FormGroup<{ partners: ArrayPartnersGroup; filesIds: FormControl<number[] | null> }>({
    partners: new FormArray<PartnerGroup>([], this.checkOnePartnerSelectionValidator),
    filesIds: new FormControl<number[]>([], this.hasAtLeastOneFileSelectionValidator),
  });
  partners$ = this.partnerStateService.partners$.pipe(
    map((partners) => this.partnerComponentService.getFilteredPartners(partners, null, false)),
    switchMap((partners) =>
      from(
        this.partnerStateService.getMissingFieldsPartnersForESignProcedure(
          this.data.task.companyId,
          partners.map((p) => p.id),
        ),
      ),
    ),
    tap((partners) => {
      this.formPartners.controls.partners.clear();
      partners.forEach(({ partner, errors }) => {
        this.formPartners.controls.partners.push(
          new FormGroup({
            partner: new FormControl(partner),
            errors: new FormControl(errors),
            selected: new FormControl({ value: false, disabled: !!errors.length }),
          }),
          { emitEvent: false },
        );
      });
    }),
  );

  constructor(
    @Inject(MODAL_DATA)
    public data: { task: Task; taskTemplate: TaskTemplate },
    public readonly partnerStateService: PartnerStateService,
    public readonly partnerComponentService: PartnerComponentService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  async onSubmit(task: Task, modalRef: ModalRef): Promise<void> {
    if (this.formPartners.invalid) {
      return;
    }
    this.formPartners.disable();
    try {
      const selectedPartners = this.formPartners.value?.partners?.filter((p) => p.selected);
      const filesIds: number[] | null | undefined = this.formPartners.value?.filesIds;
      const partnersIds: number[] = [];
      if (selectedPartners && filesIds) {
        selectedPartners?.forEach((p) => {
          partnersIds.push(p.partner?.id as number);
        });
        const esignLinkRes: TaskESignProcedure | null = await this.controlPanelTasksStateService.createESignProcedure(
          task.id,
          partnersIds,
          filesIds,
        );
        if (esignLinkRes) {
          window.open(esignLinkRes.procedure, '_blank');
          modalRef.close();
        }
      }
    } catch {
      // nothing
    }
    this.formPartners.enable();
  }

  updateSelected($event: boolean): void {
    if (this.formPartners.disabled) {
      return;
    }
    this.formPartners.controls.partners.controls
      .filter((partnerGroup) => !partnerGroup.controls.errors.value?.length)
      .forEach((partnerGroup) => partnerGroup.patchValue({ selected: $event }));
  }

  checkOnePartnerSelectionValidator(control: AbstractControl) {
    const oneSelectionPartner = (control as ArrayPartnersGroup).controls.reduce(
      (previousValue, currentValue: FormGroup) => previousValue || currentValue.controls.selected.value,
      false,
    );
    if (oneSelectionPartner) {
      return null;
    }
    return { oneSelectionPartner: oneSelectionPartner };
  }

  hasAtLeastOneFileSelectionValidator(control: AbstractControl) {
    if (control.value.length) {
      return null;
    }
    return { hasAtLeastOneFile: false };
  }

  updateFile($event: boolean, task: Task) {
    const cniSubTask = task.tasks.find((t) => t.code.split('.')[1] === 'idCheck');
    const cniIdAttachment: number[] = [];
    if (cniSubTask) {
      cniSubTask.tasks.forEach((task) => cniIdAttachment.push(...task.attachments.map((a) => a.id as number)));
    }
    this.formPartners.controls.filesIds.setValue(
      $event ? [...task.attachments.map((a) => a.id as number), ...cniIdAttachment] : [],
    );
  }
}
