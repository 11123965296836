import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dougs-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgClass, NgIf],
})
export class StepperComponent {
  private _steps!: [];

  @Input()
  set steps(steps) {
    this._steps = [].constructor(steps);
  }
  get steps() {
    return this._steps;
  }
  @Input() currentStep!: number;
  @Input() stepDetail?: string;

  trackByIndex(index: number): number {
    return index;
  }
}
