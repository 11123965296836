import { Pipe, PipeTransform } from '@angular/core';
import { TASK_LEGAL_STATE, TASK_LEGAL_STATE_GROUP } from '@dougs/task/dto';

@Pipe({
  standalone: true,
  name: 'legalState',
})
export class LegalStatePipe implements PipeTransform {
  transform(legalState: TASK_LEGAL_STATE | TASK_LEGAL_STATE_GROUP | undefined): string {
    if (!legalState) {
      return 'Non catégorisé';
    }

    switch (legalState) {
      case TASK_LEGAL_STATE.TO_PRODUCE:
        return 'À produire';
      case TASK_LEGAL_STATE.SIGNING:
        return 'Signature en cours';
      case TASK_LEGAL_STATE.CAPITAL_DEPOSIT:
        return 'Dépôt du capital en cours';
      case TASK_LEGAL_STATE.FORMALITIES:
        return 'Formalités à réaliser';
      case TASK_LEGAL_STATE.REGISTERING:
        return 'Enregistrement SIE en cours';
      case TASK_LEGAL_STATE.PENDING_DEPOSIT:
        return 'GTC ou INPI en cours';
      case TASK_LEGAL_STATE.PENDING_QUOTE:
        return 'Devis en attente';
      case TASK_LEGAL_STATE.WAITING_FOR_INFORMATION:
        return 'Dossier en attente';
      case TASK_LEGAL_STATE_GROUP.TO_DO:
        return 'À faire';
      case TASK_LEGAL_STATE_GROUP.PENDING_FORMALITIES:
        return 'Formalités en cours';
      case TASK_LEGAL_STATE_GROUP.WAITING_FOR_CUSTOMER:
        return 'Action client en cours';
      default:
        return 'Non catégorisé';
    }
  }
}
