import { Injectable } from '@angular/core';
import { concatMap, filter, lastValueFrom, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { OwnershipService } from '@dougs/core/ownership';
import { CustomerTasksStateService } from '@dougs/core/socket';
import { SessionStorageService } from '@dougs/core/storage';
import { Task } from '@dougs/task/dto';
import { FourretoutTaskStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { TaskActionFacadeService } from '../..';

// All the automatic actionable task
const AUTOMATIC_ACTIONABLE_TASK_CODES: string[] = [
  'customer:paymentStrongCustomerAuthentication',
  'customer:acceptSubscriptionPlanPolicies',
  'customer:accountingInitialAppointment',
  'customer:accountingSurvey',
  'customer:balanceSheetsAvailable',
  'customer:bankReconciliation',
  'customer:capitalDeposit',
  'customer:companyCompletion',
  'customer:entrySurvey',
  'customer:esignCreationDocuments',
  'customer:initialCreationAppointment',
  'customer:needInformation',
  'customer:pastDueBillingInvoice',
  'customer:payrollByDougs',
  'customer:payrollSurvey',
  'customer:remoteDeclarationPaymentRejected',
  'customer:salesViaPlatformDocumentUpload',
  'customer:salesViaPlatformSurvey',
  'customer:surveyAppointment',
  'customer:unseenFileAttachments',
  'customer:updatePaymentCard',
  'customer:urssafContributionsDocumentRequest',
  'customer:updateVatRegimeAfterOutsideUePurchase',
  'customer:legalLetterOfEngagement',
  'customer:irSupportCall',
  'customer:remoteTransmissionSetupFinished',
  'customer:attachsaleDeedRegisteredByTaxAuthorities',
];

// All the automatic mechanisms, there is no real task corresponding but there is an actionable task that will be automatically executed.
const AUTOMATIC_MECHANISM_CODES_FIRST: string[] = ['checkValidSubscription'];
const AUTOMATIC_MECHANISM_CODES_END: string[] = ['unseenTasks', 'checkValidCard'];

@Injectable({
  providedIn: 'root',
})
export class ActionableTasksService {
  listenSocketForActionableTasks$: Observable<void> = this.customerTasksStateService.customerTasksCreated$.pipe(
    map((companyChanged) => companyChanged.changedData),
    filter((task): task is Task => !!task),
    concatMap((task) => this.taskActionFacadeService.executeTaskAction(task)),
  );

  constructor(
    private readonly fourretoutTaskStateService: FourretoutTaskStateService,
    private readonly taskActionFacadeService: TaskActionFacadeService,
    private readonly userStateService: UserStateService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly ownershipService: OwnershipService,
    private readonly customerTasksStateService: CustomerTasksStateService,
  ) {}

  async fetchAndExecuteActionableTasks(company: Company): Promise<void> {
    if (!this.ownershipService.isMyCompany() && !this.userStateService.loggedInUser.flags.includes('doUserChecks')) {
      return;
    }
    const completionTask: Task[] = await this.fourretoutTaskStateService.getActionableTasksByCodes(
      company,
      AUTOMATIC_ACTIONABLE_TASK_CODES,
    );
    const actionTasks: Task[] = [
      ...AUTOMATIC_MECHANISM_CODES_FIRST.map((code) => ({ code }) as Task),
      ...completionTask,
      ...AUTOMATIC_MECHANISM_CODES_END.map((code) => ({ code }) as Task),
    ];
    await lastValueFrom(this.taskActionFacadeService.executeTasksAction(...actionTasks).pipe(take(1)));
    this.sessionStorageService.store('hasAutomaticallyExecutedActionableTasks', true);
  }

  async fetchAndExecuteAcceptSubscriptionPlanPolicies(company: Company): Promise<void> {
    const completionTask: Task[] = await this.fourretoutTaskStateService.getActionableTasksByCodes(
      company,
      AUTOMATIC_ACTIONABLE_TASK_CODES,
    );
    const acceptSubscriptionPlanPoliciesTask: Task | undefined = completionTask.find(
      (task) => task.code === 'customer:acceptSubscriptionPlanPolicies',
    );
    if (acceptSubscriptionPlanPoliciesTask) {
      await lastValueFrom(
        this.taskActionFacadeService.executeTasksAction(acceptSubscriptionPlanPoliciesTask).pipe(take(1)),
      );
    }
  }
}
