import { Injectable } from '@angular/core';
import { concatMap, lastValueFrom, map, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { FlashMessagesService, ModalService } from '@dougs/ds';
import { CapitalDeposit, CapitalDepositStateService } from '@dougs/legal/shared';
import { Task, TASK_STATUS } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { UserStateService } from '@dougs/user/shared';
import { ProjectValidationModalComponent } from '../modals/project-validation-modal/project-validation-modal.component';

interface ByLawsTask {
  code: string;
  title: string;
  userTask: boolean;
  done: boolean;
}

@Injectable()
export class OnboardingStageByLawsProjectService {
  private readonly defaultLawsTasksCodes = ['project', 'completion', 'sign', 'submit', 'kbis'];
  private readonly sasuWithPartnershipLawsTasksCodes = [
    'proofOfAddress',
    'project',
    'projectValidation',
    'depositCapital',
    'completion',
    'sign',
    'submit',
    'kbis',
  ];
  private capitalDeposit!: CapitalDeposit;
  private creationTask: Task | undefined;

  hasProofOfAddress = false;
  isProjectValidated = false;
  hasCapitalDeposit = false;

  canAccessProofOfAddress = false;
  canAccessProjectValidation = false;
  canAccessCapitalDeposit = false;

  tasks: ByLawsTask[] = [];
  allLawsTasks: ByLawsTask[] = [
    { code: 'project', title: 'Nous envoyons votre projet de statuts.', userTask: false, done: false },
    {
      code: 'bank',
      title:
        "Vous déposez le capital social à votre banque et transmettez l'attestation du dépôt des fonds au juriste.",
      userTask: true,
      done: false,
    },
    { code: 'completion', title: 'Nous terminons de compléter votre dossier.', userTask: false, done: false },
    {
      code: 'sign',
      title: 'Vous signez électroniquement les documents grâce au lien que vous recevez par email.',
      userTask: true,
      done: false,
    },
    { code: 'submit', title: 'Nous déposons votre dossier auprès du greffe.', userTask: false, done: false },
    {
      code: 'kbis',
      title: 'Nous recevons votre extrait Kbis : le juriste vous le notifiera.',
      userTask: false,
      done: false,
    },
    {
      code: 'proofOfAddress',
      title: 'Vous nous transmettez votre justificatif de domiciliation de la société.',
      userTask: true,
      done: false,
    },
    {
      code: 'projectValidation',
      title: 'Vous validez votre projet de statuts.',
      userTask: true,
      done: false,
    },
    {
      code: 'depositCapital',
      title: 'Vous déposez le capital social à votre banque.',
      userTask: true,
      done: false,
    },
  ];

  refreshCapitalDeposit$: Observable<void> = this.companyStateService.activeCompany$.pipe(
    concatMap((company) => this.capitalDepositStateService.refreshCapitalDeposit(company)),
  );

  capitalDeposit$: Observable<void> = this.capitalDepositStateService.capitalDeposit$.pipe(
    map((capitalDeposit) => {
      this.capitalDeposit = capitalDeposit;
      this.creationTask = this.capitalDeposit.creationTask ?? undefined;

      let lawsTasksCodes = this.defaultLawsTasksCodes;
      if (this.creationTask) {
        lawsTasksCodes = this.sasuWithPartnershipLawsTasksCodes;
        this.setHasProofOfAddress(this.companyStateService.activeCompany);
      }

      this.setLawsTasks(lawsTasksCodes);
    }),
  );

  initUserTasks$: Observable<void> = this.userTasksStateService.tasks$.pipe(
    concatMap(() =>
      Promise.all([
        this.userTasksStateService.getUserTasks(
          this.companyStateService.activeCompany,
          'customer:creationProjectValidation',
        ),
        this.userTasksStateService.getUserTasks(this.companyStateService.activeCompany, 'customer:capitalDeposit'),
        this.capitalDepositStateService.refreshCapitalDeposit(this.companyStateService.activeCompany),
      ]),
    ),
    map(([taskCreationProjectValidation, taskCapitalDeposit]) => {
      this.setIsProjectValidated(taskCreationProjectValidation[0]);
      this.setHasCapitalDeposit(taskCapitalDeposit[0]);
    }),
  );

  constructor(
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly taskService: TaskService,
    private readonly capitalDepositStateService: CapitalDepositStateService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  async openAttachProofAddressModal(): Promise<void> {
    const { AttachProofAddressModalComponent } = await import(
      '../modals/attach-proof-address-modal/attach-proof-address-modal.component'
    );
    const taskUpdated = (
      await lastValueFrom(
        this.modalService.open<Task>(AttachProofAddressModalComponent, {
          data: {
            task: this.creationTask,
          },
        }).afterClosed$,
      )
    ).data;

    if (taskUpdated) {
      await this.taskService.completeTask(taskUpdated);
    }
  }

  async openProjectValidationModal(personInCharge: { avatarUrl: string; name: string }): Promise<void> {
    const tasks: Task[] = await this.userTasksStateService.getUserTasks(
      this.companyStateService.activeCompany,
      'customer:creationProjectValidation',
    );

    const taskUpdated: Task | undefined | null = (
      await lastValueFrom(
        this.modalService.open<Task>(ProjectValidationModalComponent, {
          data: {
            task: tasks[0],
            personInCharge,
          },
        }).afterClosed$,
      )
    ).data;

    if (taskUpdated) {
      const completed: boolean = await this.taskService.completeTask(taskUpdated);
      await this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany);
      if (completed) {
        this.flashMessagesService.show('Vous avez validé votre projet de statuts 🎉', {
          type: 'success',
          timeout: 5000,
        });
      }
    }
  }

  async openCapitalDepositModal(personInCharge: { avatarUrl: string; name: string }): Promise<void> {
    const { CapitalDepositModalComponent } = await import(
      '../modals/capital-deposit-modal/capital-deposit-modal.component'
    );
    const { PartnershipCapitalDepositModalComponent } = await import(
      '../modals/partnership-capital-deposit-modal/partnership-capital-deposit-modal.component'
    );
    const tasks: Task[] = await this.userTasksStateService.getUserTasks(
      this.companyStateService.activeCompany,
      'customer:capitalDeposit',
    );

    const taskUpdated: Task | undefined | null = (
      await lastValueFrom(
        this.modalService.open<Task>(
          this.capitalDeposit.isEligible || this.capitalDeposit.isInProcess
            ? PartnershipCapitalDepositModalComponent
            : CapitalDepositModalComponent,
          {
            data: {
              task: tasks[0],
              personInCharge,
            },
          },
        ).afterClosed$,
      )
    ).data;

    if (taskUpdated) {
      const completed: boolean = await this.taskService.completeTask(taskUpdated);
      await this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany);
      if (completed) {
        this.flashMessagesService.show('Votre dépôt de capital a été déposé 🎉', {
          type: 'success',
          timeout: 5000,
        });
      }
    }
  }

  private setLawsTasks(currentLawsTasksCodes: string[] = []): void {
    this.tasks = currentLawsTasksCodes
      .map((currentCode: string) => this.allLawsTasks.find((task: ByLawsTask) => task.code === currentCode))
      .filter((task: ByLawsTask | undefined): task is ByLawsTask => !!task);
  }

  private isTaskCompleted(task?: Task): boolean {
    if (!task) {
      return false;
    }

    return task.status === TASK_STATUS.COMPLETED;
  }

  private setHasProofOfAddress(company: Company): void {
    this.canAccessProofOfAddress = true;
    this.hasProofOfAddress = !!company.proofOfAddress;

    if (this.hasProofOfAddress) {
      this.canAccessProofOfAddress = false;
    }
  }

  private setIsProjectValidated(creationProjectValidationTask: Task): void {
    this.isProjectValidated = this.isTaskCompleted(creationProjectValidationTask);
    if (!creationProjectValidationTask) {
      this.canAccessProjectValidation = false;
      return;
    }

    this.canAccessProjectValidation = true;
    if (this.isProjectValidated || !this.hasProofOfAddress) {
      this.canAccessProjectValidation = false;
    }
  }

  private setHasCapitalDeposit(capitalDepositTask: Task): void {
    this.canAccessCapitalDeposit = true;
    this.hasCapitalDeposit = this.isTaskCompleted(capitalDepositTask);

    if (this.hasCapitalDeposit || !this.isProjectValidated) {
      this.canAccessCapitalDeposit = false;
    }
  }
}
