import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  ModalContentDirective,
  ModalFooterDirective,
  PanelInfoComponent,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { AccountBalances, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { BankStatementCardComponent } from '../../bank-statement-card/bank-statement-card.component';
import { StepperComponent } from '../../stepper/stepper.component';

@Component({
  selector: 'dougs-bank-reconciliation-second-step',
  templateUrl: './bank-reconciliation-second-step.component.html',
  styleUrls: ['./bank-reconciliation-second-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ModalContentDirective,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    StepperComponent,
    NgClass,
    PanelInfoComponent,
    NgFor,
    BankStatementCardComponent,
    InputDatepickerComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    SuffixFormFieldDirective,
    ButtonComponent,
    ModalFooterDirective,
    AsyncPipe,
  ],
})
export class BankReconciliationSecondStepComponent implements OnDestroy {
  @Input() selectedAccount!: SynchronizedAccount;
  @Input()
  set selectedAccountBalances(selectedAccountBalances: AccountBalances[]) {
    this._selectedAccountBalances = selectedAccountBalances;
    this.clearAndGenerateBankReconciliationForm();
    this.isLoading =
      selectedAccountBalances.every((balance) => balance.validated) ||
      this.selectedAccount.metadata.reconciliation?.step !== 2;
    this.cdr.markForCheck();
  }
  get selectedAccountBalances() {
    return this._selectedAccountBalances;
  }
  @Input() getCurrentCardTitle!: (form: UntypedFormArray, index: number) => string;

  @Output() selectedAccountChange = new EventEmitter<SynchronizedAccount>();
  @Output() submitFormEvent = new EventEmitter();
  @Output() previousStepEvent = new EventEmitter();

  private _selectedAccountBalances!: AccountBalances[];
  isLoading!: boolean;
  potentiallyForgotDecimalsBalanceIds: (string | undefined)[] = [];
  bankReconciliationForm: UntypedFormArray = new UntypedFormArray([]);
  bankReconciliationFormSubscription: Subscription = new Subscription();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    public readonly companyStateService: CompanyStateService,
  ) {}

  private clearAndGenerateBankReconciliationForm(): void {
    this.bankReconciliationForm = new UntypedFormArray(
      this.selectedAccountBalances.map((balance, index) => {
        const validator = index ? [] : [Validators.required];

        const formGroup = new UntypedFormGroup({
          balanceId: new UntypedFormControl(balance.id),
          date: new UntypedFormControl(balance.date || '', validator),
          balance: new UntypedFormControl(balance.balance ?? '', validator),
        });

        this.bankReconciliationFormSubscription.add(
          formGroup.valueChanges.subscribe(() => {
            balance.validated = false;
          }),
        );

        return formGroup;
      }),
    );
  }

  getBalanceMaxDate(balance: AccountBalances): Date | null {
    if (balance?.maxDate) {
      return new Date(balance.maxDate);
    }

    return null;
  }

  checkAccountBalances(options: { shouldCheckDecimals: boolean } = { shouldCheckDecimals: true }) {
    if (
      this.bankReconciliationForm.invalid ||
      !this.selectedAccount.metadata?.balances ||
      !this.selectedAccountBalances
    ) {
      return;
    }

    this.isLoading = true;

    if (options.shouldCheckDecimals) {
      this.potentiallyForgotDecimalsBalanceIds = this.bankReconciliationForm.controls.reduce(
        (potentiallyForgotDecimalsArray: string[], month, index) => {
          const balance = this.selectedAccountBalances[index];

          if (balance && this.hasBalanceFieldBeenUpdated(month, balance)) {
            return [...potentiallyForgotDecimalsArray, balance.id];
          }

          return potentiallyForgotDecimalsArray;
        },
        [],
      );
    }

    if (this.potentiallyForgotDecimalsBalanceIds.length) {
      this.isLoading = false;
      return;
    }

    if (this.selectedAccountBalances.length) {
      const balances: AccountBalances[] = this.selectedAccountBalances.reduce(
        (balances: AccountBalances[], currentBalance, currentIndex) => {
          if (
            currentBalance.id === this.bankReconciliationForm.at(currentIndex).get('balanceId')?.value &&
            (!!this.bankReconciliationForm.at(currentIndex).get('balance')?.value ||
              this.bankReconciliationForm.at(currentIndex).get('balance')?.value === 0) &&
            this.bankReconciliationForm.at(currentIndex).get('date')?.value
          ) {
            return [
              ...balances,
              {
                ...currentBalance,
                balance: this.bankReconciliationForm.at(currentIndex).get('balance')?.value,
                date: this.bankReconciliationForm.at(currentIndex).get('date')?.value,
                validated: true,
                errorMessage: currentBalance.validated ? null : currentBalance.errorMessage,
              },
            ];
          }
          return balances;
        },
        [],
      );

      const previousBalances = this.selectedAccount.metadata.balances?.filter(
        (balance) => balance.triggerDate !== balances[0].triggerDate,
      );

      this.selectedAccount.metadata.balances = [...previousBalances, ...balances];
    }
    this.selectedAccountChange.emit(this.selectedAccount);
    this.submitFormEvent.emit();
  }

  hasBalanceFieldBeenUpdated(formBalance: AbstractControl, balance: AccountBalances) {
    return formBalance.get('balance')?.dirty && formBalance.get('balance')?.value % 1 === 0 && !balance.validated;
  }

  previousStep() {
    this.previousStepEvent.emit();
  }

  doesBalanceIsPotentiallyMissingDecimals(formBalanceId: string): boolean {
    return this.potentiallyForgotDecimalsBalanceIds.some((balanceId) => balanceId === formBalanceId);
  }

  resumeCheckIfNoDecimalsWarningsLeft(balanceIndex: string): void {
    this.removeDecimalsWarning(balanceIndex);

    if (!this.potentiallyForgotDecimalsBalanceIds.length) {
      this.checkAccountBalances({ shouldCheckDecimals: false });
    }
  }

  removeDecimalsWarning(balanceIndex: string): void {
    this.potentiallyForgotDecimalsBalanceIds = this.potentiallyForgotDecimalsBalanceIds.filter(
      (balanceId) => balanceId !== balanceIndex,
    );
  }

  trackByIndex(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.bankReconciliationFormSubscription.unsubscribe();
  }
}
