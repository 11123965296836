import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import {
  ButtonComponent,
  DougsDatePipe,
  FileInputComponent,
  FilePillComponent,
  TooltipDirective,
  TrackByPipe,
} from '@dougs/ds';
import { Task, TaskTemplate } from '@dougs/task/dto';
import { ToggleIconPipe } from '../../../../pipes/control-panel/task/toggle-icon.pipe';
import { TaskItemComponentService } from '../../../../services/control-panel/task/task-item.component.service';

@Component({
  selector: 'dougs-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TooltipDirective,
    NgClass,
    NgIf,
    NgFor,
    FileInputComponent,
    FilePillComponent,
    forwardRef(() => TaskItemComponent),
    TrackByPipe,
    ToggleIconPipe,
    ButtonComponent,
    DougsDatePipe,
  ],
  providers: [TaskItemComponentService],
})
export class TaskItemComponent {
  private _task!: Task;
  private _taskTemplate!: TaskTemplate;
  buttonText = '';

  @Input()
  set task(task: Task) {
    this._task = task;
    this.buttonText = this.taskItemComponentService.getButtonText(task, this._taskTemplate);
  }

  get task(): Task {
    return this._task;
  }

  @Input()
  set taskTemplate(taskTemplate: TaskTemplate) {
    this._taskTemplate = taskTemplate;
    this.buttonText = this.taskItemComponentService.getButtonText(this._task, taskTemplate);
  }

  get taskTemplate(): TaskTemplate {
    return this._taskTemplate;
  }

  constructor(public readonly taskItemComponentService: TaskItemComponentService) {}

  getAttachmentsByType(slot: string): Attachment[] {
    return this._task.attachments.filter((attachment) => attachment.type === slot);
  }

  trackById(_: number, attachment: Attachment): number {
    return +attachment.id;
  }

  trackByIndex(index: number): number {
    return index;
  }
}
