import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { CorrectTaxationAmazonSellerCentralModalComponent } from '../../modals/correct-taxation-amazon-seller-central-modal/correct-taxation-amazon-seller-central-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCorrectTaxationAmazonSellerCentralService extends TaskActionService {
  protected _label = 'Corrigez votre fiscalité dans Amazon Seller Central';
  protected _tag = 'TVA';
  protected _tagColor: Pill = 'primary';
  protected _tagIcon = 'fa-scroll';
  protected _isAvailable = true;

  constructor(
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    const isConfirmed: boolean | undefined | null = (
      await lastValueFrom(
        this.modalService.open<boolean>(CorrectTaxationAmazonSellerCentralModalComponent, {
          data: {
            task: task,
          },
        }).afterClosed$,
      )
    ).data;

    if (isConfirmed) {
      await this.taskService.completeTask(task);
    }
  }
}
