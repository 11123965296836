import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResponsiveService } from '@dougs/core/responsive';
import { ButtonComponent, TrackByPipe } from '@dougs/ds';
import { OnboardingStage } from '@dougs/legal/shared';
import { OnboardingStageByLawsProjectService } from '../../../../services/onboarding-stage-bylaws-project.service';
import { RoundCheckComponent } from '../../../utils/round-check/round-check.component';

@Component({
  selector: 'dougs-onboarding-stage-bylaws-project',
  templateUrl: 'onboarding-stage-bylaws-project.component.html',
  styleUrls: ['onboarding-stage-bylaws-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TrackByPipe, RoundCheckComponent, ButtonComponent],
  providers: [OnboardingStageByLawsProjectService],
})
export class OnboardingStageBylawsProjectComponent {
  @Input() data!: OnboardingStage;

  constructor(
    public readonly onboardingStageByLawsProjectService: OnboardingStageByLawsProjectService,
    public readonly responsiveService: ResponsiveService,
  ) {}

  get titleBtnProofOfAddress(): string {
    return this.onboardingStageByLawsProjectService.hasProofOfAddress
      ? 'Justificatif ajouté'
      : 'Attacher mon justificatif';
  }

  get titleBtnProjectValidation(): string {
    return this.onboardingStageByLawsProjectService.isProjectValidated ? 'Projet validé' : 'Valider mon projet';
  }

  get titleBtndepositCapital(): string {
    return this.onboardingStageByLawsProjectService.hasCapitalDeposit ? 'Capital social déposé' : 'Déposer mon capital';
  }
}
