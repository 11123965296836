import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { FlashMessagesService, ModalService, Pill } from '@dougs/ds';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { AcceptSubscriptionPlanPoliciesModalComponent } from '../../modals/accept-subscription-plan-policies-modal/accept-subscription-plan-policies-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerAcceptSubscriptionPlanPoliciesService extends TaskActionService {
  protected _label = 'Signer';
  protected _icon = 'fal fa-pen';
  protected _isAvailable = true;
  protected _tag = 'Lettre de mission';
  protected _tagColor: Pill = 'primary';
  protected _tagIcon = 'fa-envelope';

  constructor(
    private readonly metricsService: MetricsService,
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly userTasksStateService: UserTasksStateService,
  ) {
    super();
  }

  shouldExecuteAutomatically(): boolean {
    return true;
  }

  async execute(task: Task): Promise<void> {
    const outcome: { success: boolean; isFirstTime: boolean } | null | undefined = (
      await lastValueFrom(
        this.modalService.open<{ success: boolean; isFirstTime: boolean }>(
          AcceptSubscriptionPlanPoliciesModalComponent,
          {
            disableClose: true,
            disableCloseOnEscape: true,
            data: {
              task: task,
            },
          },
        ).afterClosed$,
      )
    ).data;

    if (outcome?.success) {
      const taskCompleted: boolean = await this.taskService.completeTask(task);
      if (outcome?.isFirstTime && taskCompleted) {
        // Accounting Checkout Terms Accepted
        this.metricsService.pushGAEvent({
          category: 'Accounting Checkout',
          label: 'Terms',
          action: 'Signed',
        });
        this.flashMessagesService.show("Nous vous confirmons l'acceptation de la lettre de mission.", {
          type: 'success',
        });
        await Promise.all([
          this.subscriptionStateService.refreshSubscriptionSignedTerms(this.companyStateService.activeCompany.id),
          this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany),
        ]);
      }
    }
  }
}
