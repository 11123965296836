import { Injectable } from '@angular/core';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { PayrollSurveyComponent } from '../../modals';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionSocialPayslipService extends TaskActionService {
  _label = 'Voir les variables';

  constructor(
    private readonly userStateService: UserStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  getIsAvailable(): boolean {
    return this.userStateService.loggedInUser?.isAccountantOrAdmin;
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const fullTask: Task | null = await this.controlPanelTasksStateService.getTask(task);

    if (fullTask) {
      const payslipSurveySubTask: Task | undefined = fullTask.tasks.find(
        (task) => task.code === 'social:payslip.survey',
      );

      if (payslipSurveySubTask?.spawnedTaskId) {
        const customerTask: Task | null = await this.controlPanelTasksStateService.getTask(
          payslipSurveySubTask.spawnedTaskId,
        );

        this.modalService.open(PayrollSurveyComponent, {
          data: customerTask,
        });
      }
    }
  }
}
