<div dougsModalTitle>
  <h6 class="mr-16">
    Vous avez réalisé une vente de prestation de services en Union Européenne. Vous êtes soumis à la déclaration
    européenne de services (DES) chaque mois où vous réaliserez une vente en Union Européenne.
  </h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small mb-16">
    Nous avons remarqué que vous avez récemment réalisé une vente de prestation de services en Union Européenne. Nous
    vous rappelons que vous êtes soumis à la déclaration européenne de services (DES) chaque mois où vous réaliserez une
    vente en Union Européenne.
  </p>
  <p class="small mb-16">
    Vous pouvez effectuer cette déclaration par voie dématérialisée sur le site des douanes:
    <a rel="noopener" target="_blank" href="https://douane.gouv.fr/">https://douane.gouv.fr/</a>
    .
  </p>
  <p class="small">
    Vous pouvez trouver plus d’informations sur ces déclarations sur notre page d'aide à ce sujet:
    <a
      rel="noopener"
      target="_blank"
      href="https://aide.dougs.fr/demarches-administratives/la-declaration-dechanges-de-biens-deb-et-la-declaration-europeenne-de-services-des"
    >
      https://aide.dougs.fr/demarches-administratives/la-declaration-dechanges-de-biens-deb-et-la-declaration-europeenne-de-services-des
    </a>
    .
  </p>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
  <dougs-button type="button" [dougsModalClose]="true">Ok, j'ai compris</dougs-button>
</div>
