<div class="bank-statement-card" [ngClass]="{ 'bank-statement-card--check': validated }">
  <div
    class="bank-statement-card__title py-8 px-8"
    [ngClass]="{ 'bank-statement-card__title--check': validated, 'color-success': validated }"
  >
    <div
      class="bank-statement-card__title__pill mr-8"
      [ngClass]="{ 'bank-statement-card__title__pill--check': validated }"
    >
      <i class="fal fa-check" *ngIf="validated"></i>
    </div>
    <p class="small" [ngClass]="validated ? 'color-gray-350' : 'color-primary-700'">{{ title }}</p>
  </div>

  <div class="bank-statement-card__content py-8 px-8">
    <ng-content></ng-content>
  </div>
</div>
